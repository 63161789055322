<template lang="pug">
  v-card(outlined)
    v-card-title Vehículos
    v-card-text
      div.mb-6.text-h6.font-weight-light Complete los datos de los vehículos:
      v-expansion-panels(v-model="activo" style="z-index: 0")
        v-expansion-panel(v-for="(vehiculo, v) in vehiculos", :key="v")
          v-expansion-panel-header(:disable-icon-rotate="tiene_error(v) !== 0")
            v-row(align="center")
              v-col.d-flex(cols="auto")
                v-icon.mr-2(color="secondary") {{ icono(vehiculo) }}
                div.primary--text.text-body-1.font-weight-medium Vehículo {{ v + 1 }}
              v-col.d-flex
                v-icon.mr-2(color="secondary") mdi-arrow-right
                div.primary--text.text-body-1.font-weight-medium {{ tipo(vehiculo) }}
            template(v-if="tiene_error(v) === 0" v-slot:actions)
              v-icon $expand
            template(v-else-if="tiene_error(v) === 1" v-slot:actions)
              v-icon(color="error") mdi-alert-circle
            template(v-else v-slot:actions)
              v-icon(color="success") mdi-check
          v-expansion-panel-content
            v-form(:ref="'form_vehiculo_' + v")
              v-row(v-if="vehiculo.tipo === 'menor'" align="center")
                v-col(cols="12" sm="6")
                  v-text-field(label="Marca" v-model="vehiculo.marca" readonly filled hide-details color="secondary")
                v-col(cols="12" sm="6")
                  v-text-field(label="Modelo" v-model="vehiculo.modelo" readonly filled hide-details color="secondary")
              v-row(align="center")
                v-col(v-if="vehiculo.tipo !== 'bici'" cols="12" sm="6")
                  v-text-field(label="Patente" v-model="vehiculo.patente" filled color="secondary" required, :rules="rules(true)", :validate-on-blur="!first_time" @blur="onBlur($event, v)")
                v-col(cols="12" sm="6")
                  v-select(label="Conductor" v-model="vehiculo.conductor" filled color="secondary", :items="conductores" @click="click" @change="onChange($event, v)" required, :rules="rules(true)", :validate-on-blur="!first_time" item-text="nombre" item-value="id")

</template>

<script>

export default {
  name: 'WidgetVehiculos',

  components: {
  },

  data: () => ({
    first_time: true,
    activo: 0,
    conductores: [],
  }),

  watch: {
  },

  computed: {
    vehiculos () {
      return this.$store.state.vehiculosArr
    }
  },

  methods: {
    onBlur (event, v) {
      if (v !== undefined && this.vehiculos[v] && this.vehiculos[v].patente) {
        this.vehiculos[v].patente = this.vehiculos[v].patente.toUpperCase().replace(/[^A-Z0-9]/g, '')
      }
    },

    icono (vehiculo) {
      switch (vehiculo.tipo) {
        case 'menor':
          return 'mdi-car'
        case 'bus':
          return 'mdi-rv-truck'
        case 'moto':
          return 'mdi-motorbike'
        case 'bici':
          return 'mdi-bicycle'
      }
      return 'mdi-help'
    },

    tipo (vehiculo) {
      switch (vehiculo.tipo) {
        case 'menor':
          return 'Vehículo menor'
        case 'bus':
          return 'Motorhome'
        case 'moto':
          return 'Motocicleta'
        case 'bici':
          return 'Bicicleta'
      }
      return 'Otro'
    },

    validar () {
      this.first_time = false
      let validos = []
      this.$store.commit('RESETEAR_CONDUCTORES')
      for (let v = 0; v < this.vehiculos.length; v++) {
        if (this.vehiculos[v].driver) {
          this.vehiculos[v].driver.pIsDriver = true
        }
        if (v === this.activo) {
          validos.push(this.validacion_form_manual(v))
        } else {
          validos.push(this.validacion_manual(v))
        }
      }
      this.$forceUpdate()
      return validos.reduce((a, b) => a && b)
    },

    validacion_form_manual (v) {
      const v1 = this.$refs['form_vehiculo_' + v]
      if (v1) {
        const valido = v1[0].validate()
        this.vehiculos[v].error = !valido
        return valido
      }
      this.vehiculos[v].error = true
      return false
    },

    validacion_manual (v) {
      let error = false
      if (this.vehiculos[v].tipo === 'menor') {
        error = !this.vehiculos[v].patente || !this.vehiculos[v].conductor
      }
      this.vehiculos[v].error = error
      return !error
    },

    click () {
      this.conductores = this.$store.state.pasajerosArr.map(p => {
        return {
          id: p.pDocNum,
          nombre: (p.pName || '') + ' ' + (p.pLastname || '')
        }
      })
    },

    onChange (change, v) {
      const chofer = this.$store.state.pasajerosArr.find(p => p.pDocNum === change)
      this.$store.state.vehiculosArr[v].driver = chofer
    },

    tiene_error (v) {
      if (this.first_time) {
        return 0
      }
      if (this.vehiculos[v].error) {
        return 1
      }
      return 2
    }
  }
}

</script>

<style type="text/css">

</style>
