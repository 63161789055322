<template lang="pug">
  v-card
    v-card-text.pb-1
      v-row.my-6.white(justify="center" fill-height)
        v-col.text-center
          div.text-md-h4.text-h6.secondary--text GRACIAS POR SU PREFERENCIA
          div.text-md-h4.text-h6 {{ resultado.nombre }}
      v-row(justify="center" fill-height style="background-color: #f5f5f5")
        v-col(cols="12")
          v-row.mt-n8(justify="center" align="center" no-gutters)
            v-col.mr-2(cols="auto")
              v-img(src="images/check.svg" max-width="40")
            v-col(cols="auto")
              div.text-center.primary--text.pacifico ¡Está todo listo para su embarque!
        v-col(cols="12" md="4")
          v-card.primary(rounded="lg")
            v-card-text.text-center.white--text.text-h6
              div.font-weight-regular.text-body-2 Nro. de reserva
              div.font-weight-black {{ resultado.code_reserve }}
        v-col.text-center.text-body-1.font-weight-medium(cols="12" md="8")
          div.font-weight-black LOS TICKETS SERÁN ENVIADOS A SU CORREO
          div.text-h5 {{ resultado.correo }}
          div.font-italic Recuerda presentarlos al momento del embarque.
      v-row(justify="center" fill-height style="background-color: #f5f5f5")
        v-spacer
        v-img(src="images/el-planeta-tierra.png" max-width="60")
        v-spacer
      v-row.pb-6(justify="center" fill-height style="background-color: #f5f5f5")
        div.text-body-1.text-center(style="color: #6da710") Prefiera presentarlos en formato digital, ayúdanos a cuidar el medio ambiente.
      v-row.primary.white--text(justify="center")
        v-col.text-center(cols="12")
          v-row(justify="center")
            v-col(cols="auto")
              div.text-h5 Detalles de la transacción
        v-col.white--text.text-md-h6.text-body-1.font-weight-medium(cols="12" md="8")
          v-row(v-if="resultado.num_reserve" dense)
            v-col(cols="6")
              div Nº Reserva
            v-col(cols="6")
              div : {{ resultado.num_reserve }}
          v-row(v-if="resultado.code_reserve" dense)
            v-col(cols="6")
              div Nº Orden de compra
            v-col(cols="6")
              div : {{ resultado.code_reserve }}
          v-row(v-if="resultado.monto" dense)
            v-col(cols="6")
              div Monto
            v-col(cols="6")
              div : {{ resultado.monto | currency('$ ', 0, { thousandsSeparator: '.' }) }}
          v-row(v-if="resultado.auth_code" dense)
            v-col(cols="6")
              div Código de autorización
            v-col(cols="6")
              div : {{ resultado.auth_code }}
          v-row(v-if="resultado.fecha" dense)
            v-col(cols="6")
              div Fecha
            v-col(cols="6")
              div : {{ resultado.fecha }}
          v-row(v-if="resultado.hora" dense)
            v-col(cols="6")
              div Hora
            v-col(cols="6")
              div : {{ resultado.hora }}
          v-row(v-if="resultado.tipo_pago" dense)
            v-col(cols="6")
              div Tipo de pago
            v-col(cols="6")
              div : {{ tipo_pago(resultado.tipo_pago) }}
      //v-row.primary.white--text(justify="center")
        v-col.text-center(cols="12")
          v-row(justify="center")
            v-col(cols="auto")
              v-icon(color="white" large) mdi-ferry
            v-col(cols="auto")
              div.text-h6 Resumen del servicio
        v-col.white--text.text-md-h6.text-body-1.font-weight-medium(cols="12" md="8")
          v-row(dense)
            v-col(cols="6")
              div Origen
            v-col(cols="6")
              div : Puerto Montt
          v-row(dense)
            v-col(cols="6")
              div Destino
            v-col(cols="6")
              div : Chaitén
          v-row(dense)
            v-col(cols="6")
              div Fecha de viaje
            v-col(cols="6")
              div : 25-03-2022
          v-row(dense)
            v-col(cols="6")
              div Hora de viaje
            v-col(cols="6")
              div : 23:00 Horas
          v-row(dense)
            v-col(cols="6")
              div Hora de presentación
            v-col(cols="6")
              div : 21:00 Horas
          v-row(dense)
            v-col(cols="6")
              div Nave
            v-col(cols="6")
              div : AGIOS
      v-row(justify="center")
        v-col(cols="auto")
          // v-btn.my-3.pa-6(color="secondary" shaped) Información sobre aduana
          div.text-h6.primary--text.my-3 INFÓRMATE SOBRE NUESTRAS NOVEDADES
      v-row.mx-n4(justify="center" dense)
        v-col(cols="6")
          v-card
            v-img.text-center.align-center.white--text(src="images/post1.jpg" height="200")
              v-overlay(absolute color="black" opacity="0.6" z-index="-1")
              v-card-text
                div.text-body-1.text-md-h6 Condiciones comerciales
                v-btn.mt-3(outlined color="white" href="https://drive.google.com/file/d/1TEqMTyNOMBvGZMdBb3rxiN0XwwmZxm4l/view" target="_blank") Ver aquí
        v-col(cols="6")
          v-card
            v-img.text-center.align-center.white--text(src="images/post2.jpg" height="200")
              v-overlay(absolute color="black" opacity="0.6" z-index="-1")
              v-card-text
                div.text-body-1.text-md-h6 Servicio a bordo
                v-btn.mt-3(outlined color="white" href="https://transportespuelche.cl/servicio-a-bordo" target="_blank") Ver aquí
        v-col(cols="12")
          v-card
            v-img.text-center.align-center.white--text(src="images/post3.jpg" height="250")
              v-overlay(absolute color="black" opacity="0.6" z-index="-1")
              v-card-text
                div.text-h5.d-flex(style="justify-content: center") Conoce nuestras rutas que conectan el Sur de Chile
                v-btn.mt-3(outlined color="white" href="https://www.transportespuelche.cl/" target="_blank") Ver aquí

      v-row.mb-0.mt-6.secondary(justify="center")
        v-col.d-flex.py-8(cols="auto")
          a(target="_blank" href="https://www.facebook.com/profile.php?id=100077591268650")
            v-img.mr-3(src="images/facebook.png" max-width="40" contain)
          a(target="_blank" href="https://x.com/navierapuelche")
            v-img.ml-3(src="images/x.png" max-width="40" contain)
          // a(target="_blank" href="https://www.instagram.com/navieraustral/")
            v-img(src="images/instagram.png" max-width="40" contain)
    //v-row(class="align-content-center justify-center", fill-height)
      v-col(cols="12")
        v-card
          v-card-title(class="text-center", v-if="exito")
            v-col(cols="12")
              v-icon(x-large color="green") mdi-check-circle
            v-col(cols="12")
              div(class="text-h4") ¡Transacción exitosa!
          v-card-text
            v-card(tile)
              v-card-text(class="pb-1")
                v-row
                  v-col(class="pb-0 font-weight-bold text-right" cols="6")
                    p(v-if="resultado.num_reserve") N° Reserva:
                    p N° Orden de compra:
                    p(v-if="resultado.monto") Monto:
                    p(v-if="resultado.auth_code") Código de autorización:
                    p Fecha:
                    p Hora:
                    p(v-if="resultado.tipo_pago") Tipo de pago:
                    div(v-if="cuotas")
                      p Tipo de cuotas:
                      p Cuotas:
                  v-col(class="pb-0" cols="6")
                    p(v-if="resultado.num_reserve") {{ resultado.num_reserve }}
                    p {{ resultado.code_reserve }}
                    p(v-if="resultado.monto") {{ resultado.monto | currency('$ ', 0, { thousandsSeparator: '.' }) }}
                    p(v-if="resultado.auth_code") {{ resultado.auth_code }}
                    p {{ resultado.fecha }}
                    p {{ resultado.hora }}
                    p(v-if="resultado.tipo_pago") {{ tipo_pago(resultado.tipo_pago) }}
                    div(v-if="cuotas")
                      p {{ tipo_cuotas(resultado.tipo_pago) }}
                      p {{ resultado.cuotas }}

                v-row.py-8(v-if="exito")
                  v-col(class="text-center pb-0")
                    div(class="text-body-1") Sus tickets se enviarán a:
                    div(class="text-h5 primary--text") {{ resultado.correo }}

                v-row(class="justify-center")
                  v-col(cols="12" class="text-center")
                    v-btn(color="primary" href="/") Volver al inicio
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    correo: '',
    correoLoading: false,
    emailRules: [
      v => v.trim() === '' || /.+@.+\..+/.test(v) || 'Correo no válido',
    ],
    resultado: {},
    cuotas: false,
    exito: false,
  }),

  mounted( ) {

    let b64 = this.$route.query.resultado
    if( !b64 ) {
      this.$router.push('/');
      return
    }
    let str
    try {
      str = atob(b64)
    } catch( err ) {
      this.$router.push('/');
      return
    }
    this.resultado = JSON.parse(str)
    this.resultado.auth_code = this.resultado.auth_code === '000000' ? undefined : this.resultado.auth_code
    this.cuotas = this.resultado.tipo_pago && this.resultado.tipo_pago !== 'VD' && this.resultado.tipo_pago !== 'VP'
    this.exito = this.resultado.exito

    this.conversion()
  },

  methods: {
    conversion () {
      if (this.resultado.gtm) {
        this.resultado.gtm.forEach(element => {
          element.currency = 'CLP'
        })
      }
      this.$gtm.trackEvent({
        event: 'purchase', // Event type [default = 'interaction'] (Optional)
        transaction_id: this.resultado.code_reserve,
        value: Number(this.resultado.monto),
        language: navigator.language,
        currency: 'CLP',
        items: this.resultado.gtm
      })
    },

    tipo_pago( tipo ) {
      switch(tipo) {
        case 'VD': return 'Tarjeta de débito';
        case 'VN':
        case 'VC':
        case 'SI':
        case 'S2':
        case 'NC': return 'Tarjeta de cŕedito';
        case 'VP': return 'Tarjeta de prepago'
      }
      return 'Otro'
    },

    tipo_cuotas( tipo ) {
      switch(tipo) {
        case 'VD': return 'Tarjeta de débito';
        case 'VN': return 'Sin cuotas';
        case 'VC': return 'Cuotas normales';
        case 'SI':
        case 'S2':
        case 'NC': return 'Sin interés';
      }
      return 'Otros'
    }
  }
}
</script>

<style type="text/css">

  .pacifico {
    font-family: 'Pacifico', cursive !important;
    font-size: x-large;
  }

</style>